<template>
  <div class="forget-password">
    <header class="header">
      <LogoIcon color="white" />
    </header>
    <main class="box">
      <ValidationObserver ref="forgotPasswordRef">
        <section class="title text-center">
          {{ $t('serviceProvider.signUp.resetYourPassword') }}
        </section>
        <section class="content">
          <div class="info text-left">
            {{ $t('serviceProvider.signUp.nextStepForgotMsg') }}.
          </div>
          <div class="inputs">
            <InputElement
              v-model="userEmail"
              type="email"
              validation-rules="required|email"
            />
          </div>
        </section>

        <ResponseAlert
          v-if="$store.getters.errorResponse.action == 'forgotPassword'"
          :response="$store.getters.errorResponse.data"
          class="mt-3"
        ></ResponseAlert>

        <section class="actions mt-3">
          <ButtonElement
            @click="$router.push({ name: 'Login', params: { role } })"
            orange-secondary
          >
            {{ $t('serviceProvider.signUp.cancel') }}
          </ButtonElement>
          <ButtonElement
            @click="handleSendForgotPasswordMail(role)"
            :loading="$store.getters.loading"
            orange
          >
            {{ $t('serviceProvider.signUp.send') }}
          </ButtonElement>
        </section>
      </ValidationObserver>
    </main>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from '@vue/composition-api';
import router from '../router';
import LogoIcon from '../components/Svgs/LogoIcon.vue';
import InputElement from '../components/FormControl/InputElement.vue';
import ButtonElement from '../components/FormControl/ButtonElement.vue';
import store from '../store';

export default {
  setup() {
    const userEmail = ref('');
    const forgotPasswordRef = ref(null);

    const handleSendForgotPasswordMail = async role => {
      const valid = await forgotPasswordRef.value.validate();
      if (!valid) return;

      const query = {
        passwordReset: 1,
      };

      switch (role) {
        case 'admin':
          store
            .dispatch('forgotPasswordCompanyUser', {
              email: userEmail.value,
            })
            .then(() => {
              router.push({ name: 'Login', params: { role: 'admin' }, query });
            });
          break;
        case 'staff':
          store
            .dispatch('forgotPasswordStaffUser', { email: userEmail.value })
            .then(() => {
              router.push({ name: 'Login', params: { role: 'staff' }, query });
            });
          break;
        case 'user':
          store
            .dispatch('forgotPasswordEndUser', { email: userEmail.value })
            .then(() => {
              router.push({ name: 'Login', params: { role: 'user' }, query });
            });
          break;
      }
    };

    return {
      userEmail,
      handleSendForgotPasswordMail,
      forgotPasswordRef,
    };
  },
  created() {
    this.$store.commit('setErrorResponse', {});
  },
  components: {
    ButtonElement,
    LogoIcon,
    InputElement,
  },
  props: {
    role: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.forget-password {
  padding: 20px;
  background-position: center;
  background-size: cover;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('../assets/pics/pexels-fauxels-3184634.png');
}
.box {
  background-color: white;
  border-radius: 10px;
  width: 460px;
  margin: 0 auto;
  padding: 32px 40px;
}
.title {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}
.info {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  margin-top: $hty-margin-low;
  text-align: center;
}
.inputs {
  margin-top: $hty-margin-low;
}
.actions {
  display: flex;
  justify-content: space-between;

  > * {
    flex-grow: 1;
  }

  > :first-child {
    margin-right: 10px;
  }
}
</style>
